import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../assets/images/ciputra/ciputra_logo.png";
import notFound from "../assets/images/ciputra/404.svg"
import swal from "sweetalert";
import CryptoJS from "crypto-js";

const Status = () => {
  const { encryptedPhone } = useParams();
  const [data, setData] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState(false);

  const decryptPhone = (encryptedPhone) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPhone, 'ikuhjsfg65khbjsd!$jhbdr');
    const originalPhone = bytes.toString(CryptoJS.enc.Utf8);
    return originalPhone;
  };

  const phone = decryptPhone(encryptedPhone);

  useEffect(() => {
    const fetchData = () => {
      fetch(`https://antrian.ciputrahospital.com/portal/api/v1/cek-status/${phone}`)
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setNotificationStatus(data.data.stop_notification)
        })
        .catch((error) => console.error("Error fetching data:", error));
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); 

    return () => clearInterval(interval); 
  }, [phone]);

  if (!data) {
    return <div>Loading...</div>;
  }

  if (data.message === "Patient not found!") {
    return <>
      <div className="flex flex-col items-center h-auto my-10">
        <img src={notFound} className="w-auto" alt="NotFound" />
      </div>
    </>
  }

  const handleNotificationClick = async (status) => {
    const newStatus = !notificationStatus;
    setNotificationStatus(newStatus);

    try {
      const response = await fetch(`https://antrian.ciputrahospital.com/portal/api/v1/status/${phone}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ stop_notification: newStatus }),
      });
      const data = await response.json();
      if (response.ok) {
        swal({
          icon: "success",
          text: `Notifikasi telah berhasil ${newStatus ? 'dihentikan' : 'dinyalakan'}`,
          className: "swal-small"
        })
      } else {
        swal("Error", "Gagal mengubah status notifikasi", "error");
      }
    } catch (error) {
      console.error("Error updating notification status:", error);
      swal("Error", "Gagal mengubah status notifikasi", "error");
    }
  };

  const totalAntrian = data.antri;
  const nama_dokter = 'dr. ' + data.data.nama_dokter;
  const nama_poli = data.data.nama_poli;
  const tanggal_periksa = new Date(data.data.tanggal_periksa).toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' });
  const nama_pasien = data.data.nama_pasien;
  const no_antrian = data.data.no_antrian_pasien;
  const layan = data.layan.no_antrian_pasien || '-';
  const statusPasien = data.data.status;
  const nama_rs = data.data.nama_rs;
  const cancelPasien = data.data.isCancel;
  const isBreak = data.data.isBreak;
  const isNotified = data.data.isNotified;
  const isCalled = data.data.isCalled;

  return (
    <>
      {(statusPasien === 'waiting' && isBreak === false && cancelPasien === false && isNotified === false) && (
        <div className="p-2 mt-4 text-center">
        {notificationStatus ? (
          <div className="text-center bg-[#008060] mb-1 rounded-md">
            <button className="text-white bg-[#008060]" onClick={handleNotificationClick}>Nyalakan Notifikasi</button>
          </div>
        ) : (
          <div className="mb-1 text-center bg-red-500 rounded-md">
            <button className="text-white bg-red-500" onClick={handleNotificationClick}>Hentikan Notifikasi</button>
          </div>
        )}
        
        <img src={logo} alt="Logo" className="mx-auto mt-4 sm:mb-2 lg:mb-12 sm:w-max-xs" />
        <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma', color: '#003974', fontSize: '22px'}}>{tanggal_periksa}</div>
        <div className="bg-[#003974] mt-2 mb-4 rounded-xl">
          <div className="py-2">
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_pasien}</p>
          </div>
          <hr className="border-gray-400" />
          <div className="py-2">
            <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> Nomor Antrian</p>
            <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> {no_antrian}</p>
          </div>
        </div>
        <div className="bg-[#003974] rounded-xl">
          <div className="py-2">
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_dokter}</p>
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_poli}</p>
          </div>
          <hr className="border-gray-400" />
          <div className="py-2">
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl">Sedang melayani Antrian</p>
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl">{layan}</p>
          </div>
          <hr className="border-gray-400" />
          <div className="py-2">
            <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl"> Jumlah antrian di Depan Anda</p>
            <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl"> {totalAntrian} Pasien</p>
          </div>
        </div>
      </div>
      )}
      {(statusPasien === 'consultation'  && cancelPasien === false && isNotified === false) && (
        <div className="p-2 mt-4 text-center">
          <div className="bg-[#003974] mt-2 mb-4 rounded-xl">
            <div className="py-2">
              <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_pasien}</p>
            </div>
            <hr className="border-gray-400" />
            <div className="py-2">
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> Nomor Antrian</p>
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> {no_antrian}</p>
            </div>
          </div>
          {/* <img src={logoImage} alt="LogoImage" className="mx-auto" height="100" width="100"/> */}
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Giliran Kamu telah tiba!</div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Silahkan Menunggu di sekitar Ruang praktek Dokter</div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Dokter dan Perawat kami sudah menunggu Anda</div>
          <img src={logo} alt="Logo" className="mx-auto mt-14 sm:w-max-xs" />
        </div>
      )}
      {(statusPasien === 'finish' && isBreak === false && cancelPasien === false && isNotified === false) && (
        <div className="p-2 mt-4 text-center">
          <div className="bg-[#003974] mt-2 mb-4 rounded-xl">
            <div className="py-2">
              <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_pasien}</p>
            </div>
            <hr className="border-gray-400" />
            <div className="py-2">
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> Nomor Antrian</p>
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> {no_antrian}</p>
            </div>
          </div>
          {/* <img src={logoImage} alt="LogoImage" className="mx-auto" height="100" width="100"/> */}
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Terima kasih untuk mempercayakan layanan Kesehatan Anda di {nama_rs}</div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Semoga lekas Sehat dan bisa beraktifitas kembali</div>
          <img src={logo} alt="Logo" className="mx-auto mt-14 sm:w-max-xs" />
        </div>
      )}
      {cancelPasien === true && (
        <div className="p-2 mt-4 text-center">
        <div className="bg-[#003974] mt-2 mb-4 rounded-xl">
          <div className="py-2">
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_pasien}</p>
          </div>
          <hr className="border-gray-400" />
            <div className="py-2">
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> Nomor Antrian</p>
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> {no_antrian}</p>
            </div>
        </div>
        {/* <img src={logoImage} alt="LogoImage" className="mx-auto" height="100" width="100"/> */}
        <div className="bg-[#003974] rounded-xl">
          <div className="py-2">
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_dokter}</p>
            <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_poli}</p>
          </div>
        </div>
        <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Terima kasih telah berkunjung ke {nama_rs}</div>
        <img src={logo} alt="Logo" className="mx-auto mt-14 sm:w-max-xs" />
      </div>
      )} 
      {(cancelPasien === false && isBreak === true && isNotified === false && isCalled === false) && (
        <div className="p-2 mt-4 text-center">
          <div className="bg-[#003974] mt-2 mb-4 rounded-xl">
            <div className="py-2">
              <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_pasien}</p>
            </div>
            <hr className="border-gray-400" />
            <div className="py-2">
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> Nomor Antrian</p>
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> {no_antrian}</p>
            </div>
          </div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>{nama_dokter}</div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Mohon izin ada keperluan mendesak</div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Anda akan kami hubungi kembali ketika Dokter sudah kembali Praktek</div>
          <img src={logo} alt="Logo" className="mx-auto mt-14 sm:w-max-xs" />
        </div>
      )}
      {(isNotified === true) && (
        <div className="p-2 mt-4 text-center">
          <div className="bg-[#003974] mt-2 mb-4 rounded-xl">
            <div className="py-2">
              <p className="text-2xl text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}>{nama_pasien}</p>
            </div>
            <hr className="border-gray-400" />
            <div className="py-2">
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> Nomor Antrian</p>
              <p className="text-2xl font-semibold text-white sm:text-2xl lg:text-3xl" style={{ fontFamily: 'Tahoma' }}> {no_antrian}</p>
            </div>
          </div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Mohon Maaf, antrian Anda kami geser</div>
          <div className="p-2 font-semibold text-center" style={{fontFamily: 'Tahoma',  fontSize: '22px'}}>Mohon kehadirannya di Depan Ruang Praktek Dokter</div>
          <img src={logo} alt="Logo" className="mx-auto mt-14 sm:w-max-xs" />
        </div>
      )}
    </>
  );
};

export default Status;
